@.rolesSiteHandler = ->
    $(document).ready(->
        bindEvents()
    )
    bindEvents = ->
        $('.roles-filter__content__select .location').select2({
            minimumResultsForSearch: Infinity
            width: 280
            dropdownCssClass: 'select-roles-dropdown'
            placeholder: gettext('Choose location'),
            allowClear: true
            language: window.current_language || 'en'
        }).on('select2:unselecting', (e) ->
            $(this).data('state', 'unselected')
        ).on('select2:open', ->
            if ($(this).data('state') == 'unselected')
                $(this).removeData('state')
                self = $(this)
                self.select2('close')
        )
        $('.roles-filter__content__select .department').select2({
            minimumResultsForSearch: Infinity
            width: 280
            dropdownCssClass: 'select-roles-dropdown'
            placeholder: gettext('Choose department'),
            allowClear: true,
            language: window.current_language || 'en'
        }).on('select2:unselecting', (e) ->
            $(this).data('state', 'unselected')
        ).on('select2:open', ->
            if ($(this).data('state') == 'unselected')
                $(this).removeData('state')
                self = $(this)
                self.select2('close')
        )
        $('.single-role__head').on('click', ->
            if $(this).parents('.single-role').hasClass('active')
                $('.single-role.active').removeClass('active').find('.single-role__content').slideUp()
            else
                $('.single-role.active').removeClass('active').find('.single-role__content').slideUp()
                $(this).siblings('.single-role__content').stop().slideToggle().parents('.single-role').toggleClass('active')
        )
        $('.single-role__content__button').on('click', ->
            $('.modal-roles').addClass('active')
            $('#job_id').val($(@).data('role-id'))
        )
        $('.inputfile').on('change', (e) ->
            fileName = e.target.value.split('\\').pop()
            $(this).siblings('label').find('span').text(fileName)
        )
